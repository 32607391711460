// --------------------------------------------------------------------------
//
//   Grid
//
// --------------------------------------------------------------------------

// ----- Default grid settings ----- //

$max-width: 1280px;
$grid-columns: 12;
$gap: 20px;
$default-feature: max-width;

// ----- Define breakpoints ----- //

$large-desktop: 1280px;
$desktop: 880px;
$tablet: 680px;
$mobile: 380px;

$large-desktop-down: max-width $large-desktop + 440px;
$desktop-down: max-width $desktop + 440px;
$tablet-down: max-width $tablet + 440px;
$mobile-down: max-width $mobile + 440px;
$small-mobile-down: max-width 580px;

$large-desktop-up: min-width $large-desktop;
$desktop-up: min-width $desktop;
$tablet-up: min-width $tablet;
$mobile-up: min-width $mobile;

// --------------------------------------------------------------------------
//   Initialise media query order
// --------------------------------------------------------------------------

@include media($large-desktop-down) {
    /* */
}
@include media($desktop-down) {
    /* */
}
@include media($tablet-down) {
    /* */
}
@include media($mobile-down) {
    /* */
}
@include media($small-mobile-down) {
    /* */
}

@include media($mobile-up) {
    /* */
}
@include media($tablet-up) {
    /* */
}
@include media($desktop-up) {
    /* */
}
@include media($large-desktop-up) {
    /* */
}

// --------------------------------------------------------------------------
//   Centering mixin for containers
// --------------------------------------------------------------------------

@mixin center {
    width: 100%;
    max-width: $max-width;

    @include media($desktop-down) {
    }

    @include media($tablet-down) {
    }

    @include media($mobile-down) {
    }
}

$x: 100px;
$y: 86px;
$z: 42px;

:root {
    --margin-x: #{$x};
    --margin-y: #{$y};
    --margin-z: #{$z};
    --gap: #{$gap};

    @include media($tablet-down) {
        --margin-x: #{$x * 0.75};
        --margin-y: #{$y * 0.75};
        --margin-z: #{$z * 0.75};
        --gap: #{$gap};
    }

    @include media($mobile-down) {
        --margin-x: #{$x * 0.5};
        --margin-y: #{$y * 0.5};
        --margin-z: #{$z * 0.5};
        --gap: #{$gap * 0.5};
    }
}
