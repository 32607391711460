.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2rem;
    transition: all ease-in-out 1s;

    &.modal__navigating {
        .modal-popup {
            opacity: 0;
        }
    }

    &.swal2-backdrop-hide {
        opacity: 0;
        pointer-events: none;
    }

    &.swal2-container.swal2-backdrop-show {
        background: rgba(0, 0, 0, 0);
    }

    .modal-popup {
        width: auto;
        max-width: 80vw;
        min-width: 40vw;
        min-height: 30vh;
        position: relative;
        border-radius: 0;
        top: -4rem;
        padding: 0;

        transition: all ease-in-out 100ms;

        &.swal2-show {
            animation: none;
        }

        &.swal2-hide {
            animation: none;
        }
    }

    .modal-htmlContainer {
        margin: 0;
        padding: var(--margin-z);
        background-color: $white;
        transition: all ease-in-out 100ms;
    }

    .modal-confirmButton {
        display: none;
    }

    .modal-closeButton {
        position: absolute;
        border: none;
    }

    .modal--navigate {
        position: absolute;
        top: 50%;
        border: none;
        background-color: none;
        svg {
            height: 2rem;
        }

        &.modal--back {
            left: -10vw;
        }
        &.modal--forward {
            right: -10vw;
        }
    }

    .modal-footer {
        position: absolute;
        top: 100%;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: none;
        margin: 0;
        padding: 1.5rem 0;
    }
}

.modal--image {
    display: block;
    max-width: 80vw;
    max-height: 60vh;
    height: auto;
    width: auto;
    margin: 0 auto;
}

.modal--title {
    color: $white;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2;
}

body {
    &::before {
        display: block;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
        content: '';
        background: rgba(0, 0, 0, 0);
        z-index: 1050;
        pointer-events: none;
        transition: all ease-in-out 400ms;
    }

    &.swal2-shown::before {
        background: rgba(0, 0, 0, 0.8);
    }
}
