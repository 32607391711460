article.single-post {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: var(--margin-y);
}

.single-post--content {
    max-width: 600px;
    width: 100%;
    margin-top: var(--margin-z);
}

.single-post--title {
    font-size: 3.3rem;
    letter-spacing: -2.5px;
    line-height: 1.1;
    margin-top: 1.5rem;
    margin-bottom: 0rem;

    @include media($tablet-down) {
        font-size: 2rem;
        letter-spacing: -2px;
    }

    @include media($mobile-down) {
        font-size: 1.8rem;
        letter-spacing: -1px;
    }
}

.single-post--date {
    font-size: 0.7rem;
}

.article-body {
    margin-top: 2rem;
}
