// --------------------------------------------------------------------------
//
//  Home Page
//
// --------------------------------------------------------------------------

.home--about--wrapper {
    background-color: $black;
    padding: calc(var(--margin-x) * 1.5) 0;

    .home--about--container {
    }

    * {
        color: $white;
    }

    h3 {
        max-width: 900px;
        letter-spacing: 0;
        font-size: 1.8rem;
        margin-bottom: 2.5rem;
    }
}

.home--exhibitions--wrapper {
    margin-top: calc(var(--margin-y) - 1em);
    margin-bottom: calc(var(--margin-x));
}

.home--exhibitions--list {
    margin-bottom: var(--margin-x);
}

.home--artists-wrapper {
    margin-top: calc(var(--margin-y) - 1em);
    margin-bottom: var(--margin-x);
}

.home--posts-container {
    max-width: calc(#{$max-width} + (var(--margin-y)) * 2);
    padding: calc(var(--margin-y) + var(--gap));
    padding-bottom: 0;
    padding-top: calc(var(--margin-z));
    background: #f7f6f4;

    @include media($tablet-down) {
        padding: var(--margin-y) var(--gap);
        padding-bottom: 0;
    }

    @include media($mobile-down) {
        padding: var(--margin-y) var(--gap);
        padding-bottom: 0;
    }
}

.home--feature-post {
    grid-column: 1/-1;
    position: relative;
    overflow: hidden;
    width: 100%;

    &:hover .home--feature-post--image {
        transform: scale(1.1);
        opacity: 0.7;
    }
}

.home--feature-post--image--wrapper {
    overflow: hidden;
    width: 100%;
}

.home--feature-post--image {
    display: block;
    width: 100%;
    transition: transform ease-in-out 3s, opacity ease 1s;
}

.home--feature-post--details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 0 0 10% 5%;

    background-color: rgba($black, 0.5);
    color: $white;
    font-size: 1.4rem;

    @include media($tablet-down) {
    }
    @include media($mobile-down) {
        font-size: 1rem;
    }
}

.home--feature-post--details--title {
    color: $white;
    font-size: 4rem;
    line-height: 1;
    max-width: 60%;
    margin-bottom: 0.5rem;

    @include media($tablet-down) {
        font-size: 3rem;
        line-height: 1;
        max-width: 80%;
    }

    @include media($mobile-down) {
        font-size: 2rem;
        line-height: 1;
        max-width: 80%;
    }
}

.post-list.home--posts--list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: var(--margin-z) 3%;

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

.home .subscribe-form--inner {
    border-top: none;
}
