// --------------------------------------------------------------------------
//
//   Fonts
//
// --------------------------------------------------------------------------
/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/4a6087');
@import url('//hello.myfonts.net/count/4cf704');

@font-face {
    font-family: 'UniversLTPro-45Light';
    src: url('assets/fonts/UniversLTPro45Light/font.woff2') format('woff2'),
        url('assets/fonts/UniversLTPro45Light/font.woff') format('woff');
}
@font-face {
    font-family: 'UniversLTPro-75Black';
    src: url('assets/fonts/UniversLTPro75Black/font.woff2') format('woff2'),
        url('assets/fonts/UniversLTPro75Black/font.woff') format('woff');
}

@font-face {
    font-family: 'UniversLTPro-85XBlack';
    src: url('assets/fonts/UniversLTPro85XBlack/font.woff2') format('woff2'),
        url('assets/fonts/UniversLTPro85XBlack/font.woff') format('woff');
}
