// --------------------------------------------------------------------------
//
//  Buttons
//
// --------------------------------------------------------------------------

@mixin button($primary: $base-link-color, $content: $white) {
    display: inline-block;
    padding: 1.6rem 1.8rem 1.4rem;
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 0;
    outline: 0;
    zoom: 1;
    cursor: pointer;
    user-select: none;

    color: $black;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-family: $font-family-bold;
    font-weight: normal;
    line-height: 1;

    &:hover {
        outline: 0;
        background: none;
    }

    &:active,
    &.active {
        outline: 0;
    }

    &.disabled,
    &[disabled] {
        background-image: none;
        opacity: 0.4;
        cursor: not-allowed;
        box-shadow: none;
    }
}
