.exhibition-single {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 52% 40%;
    grid-template-rows: auto auto;
    gap: 1.2rem 1rem;
    grid-template-areas:
        '. .'
        '. .';
    justify-content: start;
    justify-items: start;

    @include media($tablet-down) {
    }

    @include media($mobile-down) {
        display: block;
    }
}

.exhibition-single--details {
    @include media($mobile-down) {
        margin-bottom: 2.3rem;
    }
}

.exhibition-links {
    a {
        display: inline-block;
        font-size: 1.1rem;
        color: $black;
        border-bottom: 1px solid #cfcfcd;
        margin-bottom: 0.6rem;
    }
}

.wrapper.title .exhibition-title {
    margin-bottom: 1.2rem;
}

.exhibition-date {
    margin-bottom: 2rem;
}

.exhibition-location {
    margin-bottom: 2rem;
}

.exhibition-schedule {
}

.exhibition-single--intro-heading {
    margin-top: 0;
    font-size: 1.6rem;
}
.exhibition-single--intro {
}

.feature-image-wrapper {
    aspect-ratio: 5/2;
}

.works--container {
    margin-top: var(--margin-x);

    hr {
        margin-top: var(--margin-y);
    }

    > h3 {
        padding: 0;
        margin: 0.5rem 0 0 0;
        height: var(--margin-x);
        display: flex;
        align-items: center;
    }
}

.works {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) 3%;

    min-height: 500px;

    margin-bottom: calc(var(--margin-y) - 2rem);

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

.tease-work {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .work--image--wrapper {
        overflow: hidden;
    }

    .work--image {
        display: block;
        transition: transform ease-in-out 3s, opacity ease 1s;
    }

    &:hover {
        .work--image {
            transform: scale(1.3);
            opacity: 0.5;
        }
    }

    .work--details {
        margin-top: 1.4rem;
        color: $black;
        font-size: 0.8rem;

        .work--details--title {
            font-size: 0.9rem;
            line-height: 1.2;
            margin: 0.5rem 0 0.3rem;
        }
    }
}

.exhibition-artist-wrapper {
    background-color: $light-gray;
    margin-bottom: var(--margin-x);
    padding: var(--margin-x) 0;
}

.exhibition-artist-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto auto;
    grid-template-columns: 50% 36%;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) var(--margin-y);

    @include media($mobile-down) {
        display: block;
    }
}

.exhibition-artist--introduction {
    grid-column: 1 / span 2;
    width: 65%;
    font-size: 2rem;
    font-family: $font-family-bold;
    line-height: 1.2;

    @include media($mobile-down) {
        font-size: 1rem;
    }
}

.exhibition-artist--blurb {
    @include media($mobile-down) {
        margin-top: var(--margin-y);
    }
}
