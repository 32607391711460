// --------------------------------------------------------------------------
//
//  Footer
//
// --------------------------------------------------------------------------

#subscribe-form {
    margin-top: var(--margin-y);
}

.subscribe-form-wrapper {
}

.subscribe-form--inner {
    padding: var(--margin-y) 0;
    border-top: 1px solid $dark-gray;

    > h3 {
        font-size: 2rem;
        max-width: $max-width * 0.75;

        @include media($mobile-down) {
            font-size: 1.3rem;
        }
    }
}

.subscribe-form--toggle {
    display: inline-block;
    color: $black;

    cursor: pointer;

    @include media($tablet-down) {
        margin-bottom: 2rem;
    }

    @include media($mobile-down) {
        margin-bottom: 1rem;
    }

    &:active,
    &:focus,
    &:hover {
        color: $black;
    }

    &::after {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        margin-left: 10px;
        content: '';
        border-bottom: 1px solid $black;
    }
}

.subscribe-form--form {
    max-width: $max-width * 0.8;
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.5s;
}

.subscribe-form__open {
    max-height: 120vh;
}

.subscribe-form--form {
    position: relative;
    padding-bottom: 1rem;

    form.subscribe-form {
        padding-top: 3rem;
    }
    .acf-fields.-left > .acf-field {
        max-width: 25rem;
    }
}

form {
    .acf-required {
        color: $dark-gray;
    }

    .acf-fields > .acf-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 1rem 0;
        border-top: none;
    }
    .acf-fields.-left > .acf-field {
        padding: 0;
        margin-bottom: 0.8rem;

        &.acf-error {
            padding-top: 3rem;
        }
    }

    .acf-fields.acfe-column-wrapper.-left
        > .acf-field.acf-field-acfe-column
        > .acf-input {
        width: 100%;
    }
    .acf-fields.-left > .acf-field:before {
        display: none;
    }
    .acf-fields.-left > .acf-field > .acf-label {
        padding: 0;
        width: 7rem;
        flex-shrink: 0;
    }
    .acf-fields.-left > .acf-field > .acf-input {
        padding: 0;
        width: 100%;
        flex-shrink: 1;
        position: unset;
    }
    .acf-fields.acfe-column-wrapper
        > .acf-field.acf-field-acfe-column[data-columns='6/12'] {
        max-width: 75%;

        @include media($mobile-down) {
            max-width: 100%;
        }
    }

    .acf-field-text,
    .acf-field-email {
        border-bottom: 1px solid #a9a8a8;

        .acf-error-message {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    .acf-field input[type='text'],
    .acf-field input[type='email'] {
        padding: 0.5rem;
        border-bottom: none;
        font-size: 1.1rem;

        &:focus,
        &:focus-visible {
            border: none;
            outline: none;
        }
    }

    .acf-fields.-left > .acf-field-checkbox {
        flex-direction: column;
        align-items: flex-start;
        max-width: 28rem;

        .acf-label {
            width: 100%;
            padding: 0.5rem 0;
            border-bottom: 1px solid #a9a8a8;
            margin-bottom: 1rem;
        }
    }
    .acf-checkbox-list {
        li {
            padding: 0.3rem;
        }
    }

    .acf-fields > .acf-field-textarea {
        flex-direction: column;
        align-items: flex-start;
        .acf-input {
            textarea {
                border-bottom: 1px solid #a9a8a8;
            }
        }
    }

    ul.acf-radio-list li label,
    ul.acf-checkbox-list li label {
        padding-left: 1rem;
        display: block;

        input {
            position: absolute;
            left: 0;
            top: 0.5rem;
        }
    }

    .hide-label {
        .acf-label {
            display: none;
        }
    }
}

// --------------------------------------------------------------------------
//
//  Upper Footer
//
// --------------------------------------------------------------------------

#upper-footer {
    background-color: $light-gray;
    color: $black;
    padding: var(--margin-y) 0;
}

.upper-footer--container {
    display: flex;
    justify-content: space-between;
}

.address {
    max-width: 460px;
    min-width: 300px;

    font-size: 1.15rem;
    line-height: 1.45;

    @include media($mobile-down) {
        font-size: 1rem;
    }

    .address--large {
        font-size: 1.8rem;
        line-height: 1.15;
        font-family: $font-family-light;
        font-weight: bold;

        @include media($mobile-down) {
            font-size: 1.2rem;
        }
    }
}

.opening-hours {
    display: block;
    margin-top: 10px;
}

.social {
    max-width: 410px;
    font-size: 1.2rem;

    @include media($mobile-down) {
        font-size: 1rem;
    }
}

.social--link {
    display: inline-block;
    margin-top: 20px;
}

#footer {
    background-color: $black;
    padding: var(--margin-x) 0;
}

.footer--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
