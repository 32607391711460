.artist-archive {
    margin-top: var(--margin-y);
}

.artist-list {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) 3%;

    padding-bottom: var(--margin-y);

    border-bottom: 1px solid $dark-gray;

    &:last-child {
        border-bottom: none;
    }

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

.artist-archive .artist-list {
    // margin-bottom: var(--margin-z);
}

.artist-archive--title {
    padding: 0;
    margin: 0;
    height: var(--margin-x);
    display: flex;
    align-items: center;
}

.artist-tease {
    position: relative;
    overflow: hidden;

    .artist-tease--image-wrapper {
        overflow: hidden;
    }

    .artist-tease--image {
        display: block;
        transition: transform ease-in-out 3s, opacity ease 1s;
    }

    &:hover {
        .artist-tease--image {
            transform: scale(1.3);
            opacity: 0.5;
        }
    }

    .artist-tease--details {
        margin-top: 1.4rem;
        color: $black;
        font-size: 0.8rem;

        .artist-tease--details--title {
            font-size: 0.9rem;
            line-height: 1.2;
            margin: 0.5rem 0 0.4rem;
        }
    }
}
