.post-list {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) 3%;

    margin-bottom: var(--margin-x);

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

.blog .post-list {
    margin-top: var(--margin-x);
    padding-bottom: var(--margin-y);
}

.tease-post {
    .post--image--wrapper {
        overflow: hidden;
    }

    .post--image {
        display: block;
        transition: transform ease-in-out 3s, opacity ease 1s;
    }

    &:hover .post--image {
        opacity: 0.5;
        transform: scale(1.1);
    }

    .post--details {
        padding-top: 1rem;
        color: $black;
        font-size: 0.7rem;
    }

    .post--details--title {
        font-size: 1.3rem;
        margin-top: 0.8rem;
        margin-bottom: 0.7rem;

        @include media($tablet-down) {
            font-size: 1.3rem;
        }
    }

    .post--kicker {
        font-size: 0.8rem;
    }
}
