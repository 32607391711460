.artist-single {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 52% 40%;
    grid-template-rows: auto auto;
    gap: 1.2rem 1rem;
    grid-template-areas: '. .';
    justify-content: start;
    justify-items: start;

    @include media($tablet-down) {
    }

    @include media($mobile-down) {
        display: block;
    }
}

.artist-single--details {
}

.artist-subtitle {
    margin-bottom: 3.5rem;

    @include media($mobile-down) {
        margin-bottom: 1rem;
    }
}

.artist-links {
    a {
        display: inline-block;
        font-size: 1.1rem;
        color: $black;
        border-bottom: 1px solid #cfcfcd;
        margin-bottom: 0.6rem;
    }
}

.exhibition-list {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) 3%;

    margin-bottom: var(--margin-y);

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

.single-artist .exhibition-list .exhibition--details--artist {
    display: none;
}
