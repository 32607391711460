// --------------------------------------------------------------------------
//
//  Forms
//    Form elements, plugin styling
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Variables
// --------------------------------------------------------------------------

$form-element-border: 1px solid $black;

// --------------------------------------------------------------------------
//   Generic form elements
// --------------------------------------------------------------------------

form {
    // ----- Remove default styling ----- //

    ul,
    ol,
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    label {
        font-weight: 600;
    }
}

// ----- Buttons ----- //

button,
[type='button'],
[type='submit'],
[type='reset'] {
    @include button;
}

// ----- Input types ----- //

[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
textarea,
select {
    max-width: 100%;
    width: 100%;
    border: none;
    border-bottom: $form-element-border;
    padding: 5px 10px;
    font-weight: normal;

    &:hover {
        background: $white;
    }

    &:focus {
        background: $white;
    }
}

[type='checkbox'],
[type='radio'] {
    margin-right: 10px;
}

.contact-form {
    max-width: 50%;

    @include media($tablet-down) {
        max-width: 100%;
    }

    @include media($mobile-down) {
        max-width: 100%;
    }

    margin: var(--margin-x) 0;

    .acf-fields > .acf-field {
        padding: 0.5rem 0;
    }

    .acf-form-submit {
        margin-top: var(--margin-z);
    }
}
