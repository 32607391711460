// --------------------------------------------------------------------------
//
//  Sections
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Wrapper
// --------------------------------------------------------------------------

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

// --------------------------------------------------------------------------
//   Container / Centering
// --------------------------------------------------------------------------

.container {
    @include center;
    padding: 0 $gap;
    flex-wrap: wrap;

    @include media($mobile-down) {
        padding: 0 10px;
    }
}

.section--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: var(--margin-x);
}

.section--header--title {
    margin-bottom: 0;
    font-size: 1.6rem;
}

.section--header--link {
    font-size: 1rem;

    @include media($tablet-down) {
        font-size: 1rem;
    }

    &::after {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        margin-left: 10px;
        content: '';
        border-bottom: 1px solid $black;
    }
}

hr.wp-block-separator {
    border-top: none;
    border-bottom: 1px solid $dark-gray;
    margin: var(--margin-y) 0;
}
