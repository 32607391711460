.glide {
    * {
        box-sizing: inherit;
    }
}
.slider {
    padding: 0 var(--margin-x);

    @include media($tablet-down) {
        padding: 0 var(--margin-y);
    }

    @include media($small-mobile-down) {
        padding: 0 10px;
    }

    .glide--slides {
        transform: none !important;
        width: auto !important;
        display: grid;
        grid-template-areas: 'slide';
    }

    .glide--slide {
        position: relative;
        opacity: 0;
        transition: opacity 0.8s ease-in-out;
        grid-area: slide;
    }
    .glide__slide--active {
        z-index: 1;
        opacity: 1;
    }
}

.glide--track {
    overflow: hidden;
}

.glide--slides {
    margin: 0;
    padding: 0;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
}

.glide--slide {
    position: relative;
    height: calc(100vh - 140px);
    overflow: hidden;
    margin: 0 !important;
    padding: 0;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    @include media($small-mobile-down) {
        height: calc(80vh - 60px);
    }
}

.glide--slide--image,
.glide--slide--video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $black;

    iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.glide--slide--content--container {
    width: 100%;
    max-width: 1000px;
}

.glide--slide--content {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3vw;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    color: $white;
    font-size: 1rem;

    padding-left: 3vw;

    p {
        margin-bottom: 1.5vw;
        max-width: 40%;

        @include media($tablet-down) {
            max-width: 60%;
        }
        @include media($small-mobile-down) {
            max-width: 80%;
        }
    }

    @include media($tablet-down) {
        font-size: 1rem;
        padding-top: 6vw;
    }

    @include media($small-mobile-down) {
        font-size: 1rem;
        padding-top: 5vw;
    }

    p strong {
        font-size: 1.3vw;
        font-weight: normal;

        @include media($tablet-down) {
            font-size: 2rem;
        }

        @include media($small-mobile-down) {
            font-size: 1.2rem;
        }
    }

    a {
        color: $white;

        &::after {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            margin-left: 10px;
            content: '';
            border-bottom: 1px solid $white;

            @include media($small-mobile-down) {
                width: 20px;
            }
        }
    }

    h1,
    h2,
    h3 {
        font-family: $font-family-extra-black;
        position: relative;
        font-size: 5.5vw;
        line-height: 0.85;
        color: $white;
        margin-bottom: 3rem;
        left: -0.4vw;
        letter-spacing: 0;

        @include media($tablet-down) {
            font-size: 10vw;
        }

        @include media($small-mobile-down) {
            font-size: 13vw;
        }

        strong {
            color: $yellow;
        }
    }
}

.glide--arrows-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    padding: 0 calc(var(--margin-x));

    @include media($tablet-down) {
        padding: 0 calc(var(--margin-y) + 1vw);
    }

    @include media($small-mobile-down) {
        padding: 0 calc(1rem + 2vw);
    }

    @include media($desktop-down) {
        align-items: flex-end;
    }
}

.glide--arrows {
    @include media($small-mobile-down) {
        font-size: 5vw;
    }
    width: 100%;
    max-width: 1000px;
}

.glide--arrow {
    background: none;
    border: none;

    @include media($small-mobile-down) {
        padding: 1rem;
    }

    svg {
        width: 0.5rem;
        @include media($small-mobile-down) {
            width: 0.3rem;
        }
    }
}

.glide--arrow--left {
    position: relative;
    left: -0.45rem;
}
