.gallery--container {
    margin-bottom: var(--margin-y);
}

.gallery {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: inherit;
    }
}

.gallery--slide {
    width: 100%;
    height: 25rem;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    backface-visibility: visible;

    @include media($tablet-down) {
        height: 20rem;
    }
    @include media($mobile-down) {
        height: 15rem;
    }
}

.gallery--image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.glide__slide--active {
    z-index: 1;
}
