.exhibition-features {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--margin-x);
    margin-bottom: var(--margin-z);
    padding: calc(var(--margin-y) / 2) 0 var(--margin-y);
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;

    @include media($small-mobile-down) {
        flex-direction: column;
        border-top: none;
        margin-top: 0;
    }
}

.exhibition-features--feature {
    max-width: 48.5%;

    h3 {
        margin-bottom: var(--margin-z);
    }

    @include media($small-mobile-down) {
        max-width: 100%;
        margin-bottom: var(--margin-z);

        &:last-child() {
            margin-bottom: 0;
        }
    }
}

.exhibition--image {
    display: block;
    transition: transform ease-in-out 3s, opacity ease 1s;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.exhibition-features--exhibition {
    display: block;
    position: relative;
    aspect-ratio: 4/3;
    overflow: hidden;

    &:hover {
        .exhibition--image {
            transform: scale(1.2);
        }
        .exhibition--details {
            background-color: rgba($black, 0.3);
        }
    }

    .exhibition--details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: var(--margin-z) 2rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        background-color: rgba($black, 0.5);
        transition: all ease-in-out 1s;

        white-space: initial;
    }

    .exhibition--details--title {
        color: $white;
        font-size: 24px;
        margin-bottom: 0;
    }

    .exhibition--details--date {
        color: $white;
    }
}

.exhibition-archive {
    h3 {
        margin-bottom: 0;
    }
}

.exhibition-archive--selector {
    line-height: calc(var(--margin-z) * 2);
}

.exhibition-archive--selector--link {
    font-family: $font-family-bold;
    font-size: 0.8rem;
    color: $charcoal;
    margin-right: 0.5rem;

    &:hover {
        color: $black;
    }

    &.selector--link__active {
        border-bottom: 2px black solid;
        color: $black;
    }
}

.exhibition-archive--list {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    justify-content: start;
    justify-items: start;
    gap: var(--margin-y) 3%;

    min-height: 500px;

    @include media($tablet-down) {
        grid-template-columns: 1fr 1fr;
    }

    @include media($mobile-down) {
        grid-template-columns: 1fr;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.exhibition-archive--exhibition {
    position: relative;
    overflow: hidden;

    &.exhibition__hidden {
        display: none;
        opacity: 0;
    }

    &.exhibition__active {
        display: block !important;
        opacity: 1;
        animation: fade-in 0.5s ease-in-out 0ms;
    }

    .exhibition--image--wrapper {
        overflow: hidden;
    }

    &:hover {
        .exhibition--image {
            transform: scale(1.3);
            opacity: 0.5;
        }
    }

    .exhibition--details {
        margin-top: 1.4rem;
        color: $black;
        font-size: 0.8rem;
        white-space: initial;

        .exhibition--details--artist {
            font-size: 1.2rem;
            line-height: 1;
        }

        .exhibition--details--title {
            font-size: 0.9rem;
            line-height: 1.2;
            margin: 0.5rem 0 0.4rem;
        }

        .exhibition--details--date {
        }
    }
}
