// --------------------------------------------------------------------------
//
//  Type
//    Default typographic styles and elements
//
// --------------------------------------------------------------------------

body {
    font-family: $font-family-light;
    font-size: 15px;
    font-weight: normal;
    font-size-adjust: auto;
    line-height: 1.5;

    text-align: left;
    word-wrap: break-word;

    color: $base-body-color;

    text-rendering: optimizeLegibility;

    @include media($mobile-down) {
        font-size: 14px;
    }
}

// --------------------------------------------------------------------------
//   Headings
// --------------------------------------------------------------------------

h1 + h2,
h2 + h3,
h3 + h4,
h5 + h6 {
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $base-heading-color;
    font-family: $font-family-bold;
    font-weight: 600;
    line-height: 1.15;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 36px;
}
h2 {
    font-size: 32px;
    letter-spacing: -2px;
}
h3 {
    font-size: 1.2rem;
    // letter-spacing: -1px;
}
h4 {
    font-size: 16px;
    letter-spacing: -0.2px;
}
h5 {
    font-size: 16px;
}
h6 {
    font-size: 14px;
}

@include media($mobile-down) {
    h1 {
        font-size: 24px;
        letter-spacing: 0px;
    }
    h2 {
        font-size: 22px;
        letter-spacing: -1px;
    }
    h3 {
        font-size: 20px;
        letter-spacing: -0.5px;
    }
    h4 {
        font-size: 14px;
        letter-spacing: 0px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 14px;
    }
}

// --------------------------------------------------------------------------
//   Content Elements
// --------------------------------------------------------------------------

a {
    color: $base-link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $base-link-hover;
    }
}

p {
    margin-top: 0;
    margin-bottom: 20px;
}

figure {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}

hr {
    display: block;
    margin: 0;
    border: 0;
    border-top: 1px solid $gray;
    color: $charcoal;
    background-color: $charcoal;
}

blockquote {
    margin: 0 0 $gap;
    font-family: cambria, georgia, times, serif;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    background: $light-gray;
    padding: $gap;
    font-style: italic;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

ins {
    background: $yellow;
    color: $black;
    text-decoration: none;
}

mark {
    background: $yellow;
    color: $black;
    font-style: italic;
    font-weight: bold;
}

table {
    width: 100%;
}
