// --------------------------------------------------------------------------
//
//   Primary Navigation
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Wrapper
// --------------------------------------------------------------------------

.primary-navigation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 300;
    pointer-events: none;
}

// --------------------------------------------------------------------------
//   Menu
// --------------------------------------------------------------------------

.primary-navigation {
    min-height: 100vh;
    width: 100%;
    max-width: 600px;
    margin: 0;
    padding: 10rem 1rem 1rem 3rem;

    transform: translateX(-100%);
    transition: all ease-in-out 0.5s;

    background-color: transparentize($color: $black, $amount: 0.05);
    pointer-events: all;

    font-family: $font-family-bold;
    color: $white;
    font-size: 3.5rem;

    @include media($tablet-down) {
        font-size: 2.4rem;
        padding-top: 8rem;
    }

    @include media($mobile-down) {
        font-size: 1.8rem;
        padding-top: 6rem;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    .menu-item {
        display: block;
        list-style-type: none;

        a {
            color: $white;
            transition: all ease 0.3s;
            will-change: color;
        }

        &.menu-item__nofocus > a {
            color: #4e4f4d;
        }

        ul {
            margin: 0;
            padding: 1rem 0 0 0;
            font-size: 1.5rem;
            line-height: 2;

            @include media($mobile-down) {
                font-size: 1.2rem;
            }

            .menu-item {
                display: block;

                a {
                    display: inline-block;
                }
            }
        }
    }
}

.navigation__open {
    .primary-navigation {
        transform: translateX(0);
    }
}

.navigation-toggle {
    z-index: 320;
    display: block;
    position: relative;
    padding: 8px;

    @include media($mobile-down) {
        padding: 0px;
    }

    cursor: pointer;

    div {
        position: relative;
        width: 30px;
        height: 20px;
    }

    span {
        display: block;
        width: 30px;
        height: 2px;
        background: black;
        position: absolute;
        transition: transform 0.5s ease-in-out, top 0.25s ease-in-out 0.5s,
            opacity 0.25s ease-in-out 0.5s;
        transform-origin: center;
        &.top {
            top: 0px;
        }
        &.bottom {
            top: 12px;
        }
    }

    &.navigation-toggle__open {
        span {
            background-color: $white;
            transition: transform 0.5s ease-in-out 0.3s, top 0.25s ease-in-out,
                opacity 0.25s ease-in-out, background 0.5s ease-in-out;

            &.top {
                top: 6px;
                transform: rotate(45deg);
            }
            &.bottom {
                top: 6px;
                transform: rotate(-45deg);
            }
        }
    }
}
