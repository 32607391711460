// --------------------------------------------------------------------------
//
//   Variables
//
// --------------------------------------------------------------------------

// ----- Colours ----- //

$black: #1a1919;
$charcoal: #6f6f6f;
$light-charcoal: #8a8b8a;
$dark-gray: #bebdbd;
$gray: #dadada;
$light-gray: #ececea;
$white: #fffffe;

$blue: #00addd;
$green: #9c9c48;
$yellow: #fff005;
$red: #d78f8e;

// ----- Typography ----- //

$base-body-color: $black;
$base-heading-color: $black;

$base-link-color: $black;
$base-link-hover: lighten($base-link-color, 20%);

$base-border-color: $gray;
$base-border-radius: 2px;

$font-family-light: 'UniversLTPro-45Light', 'Helvetica Neue', Arial, Helvetica;
$font-family-bold: 'UniversLTPro-75Black', 'Helvetica Neue', Arial, Helvetica;
$font-family-extra-black: 'UniversLTPro-85XBlack', 'Helvetica Neue', Arial,
    Helvetica;

// ----- Transitions ----- //

$navigation-transition: all 500ms $ease-in-out-quad;
