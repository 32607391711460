.wrapper.title {
    padding: var(--margin-y) 0 var(--margin-y);
    background-color: $light-gray;

    h2 {
        font-size: 4rem;
        line-height: 1;
        margin-bottom: 0;

        @include media($mobile-down) {
            font-size: 2rem;
        }
    }

    .subtitle {
        color: $black;
        font-size: 1.8rem;
        font-family: $font-family-bold;
        line-height: 1.3;

        margin-top: calc(var(--margin-y) - 30px);
        margin-bottom: 0;

        max-width: $max-width * 0.65;

        @include media($tablet-down) {
            font-size: 1.5rem;
            max-width: 65%;
        }

        @include media($mobile-down) {
            font-size: 1.2rem;
            margin-top: 1.3rem;
            max-width: 500px;
        }
    }
}
