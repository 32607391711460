.simple-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.simple-page--image {
}

.simple-page--content {
    margin: var(--margin-y) 0;
    max-width: 800px;
}

.simple-page--title {
    font-size: 3rem;
    margin-bottom: var(--margin-z);

    @include media($tablet-down) {
        font-size: 2rem;
    }
    @include media($mobile-down) {
        font-size: 1.6rem;
    }
}

.simple-page--body {
}
