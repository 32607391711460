// --------------------------------------------------------------------------
//
//  Header
//
// --------------------------------------------------------------------------

.wrapper.header {
    z-index: 320;

    background-color: $white;

    position: sticky;
    top: -1px;
    padding-top: 1px;

    &.header__sticky {
        box-shadow: 0px -10px 18px 0px $black;

        .header-container {
            height: 60px;
        }
    }

    .header-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        z-index: 350;
        height: 60px;
        width: 100%;
        max-width: 1385px;
        padding: 0 1rem;

        @include media($tablet-up) {
            height: 140px;
            justify-content: space-between;
        }

        transition: all ease-in-out 0.3s;
    }

    .logo {
        max-width: 100px;
        order: 2;
        margin-bottom: 0;

        @include media($tablet-up) {
            max-width: 130px;
        }
    }

    .navigation-toggle {
        order: 1;
        position: absolute;
        left: 1rem;
        top: 1.4rem;

        @include media($tablet-up) {
            position: relative;
            left: 0;
            top: 0;
        }
    }

    .subscribe {
        display: none;
        order: 3;
        font-size: 0.8rem;
        font-weight: bold;

        .subscribe--link {
            text-transform: uppercase;
        }

        @include media($tablet-up) {
            display: block;
        }
    }
}
